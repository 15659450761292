import React from 'react';

export default class Home extends React.Component {
  state = {
  };

  componentDidMount () {
  } 

  render() {
    return (
     <a href="/create">Create Game</a>
    );
  }
}
